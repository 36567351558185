<template>
  <v-form
    class="ml-3"
    ref="form"
    v-model="isValid"
  >
    <SectionTitle
      class="mb-0"
      title="Dados do Cliente"
    >
      <template slot="action-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="seeClientDetails"
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              icon
              color="primary"
              small
            >
              <v-icon small>fas fa-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver detalhes do cliente</span>
        </v-tooltip>
      </template>
    </SectionTitle>
    <ClientData :client="client" />

    <SectionTitle
      class="mb-0"
      title="Atividade"
    />

    <Activity :crm="crm" />

    <SectionTitle
      class="mb-0"
      title="Observações"
    />
    <Observations
      @setHistoric="(v) => crm.historic.unshift(v)"
      @removeObservation="(index) => crm.historic.splice(index, 1)"
      :crmHistoric="crm.historic"
      :CRM_ID="crm.id"
    />
  </v-form>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    ClientData: () => import('./form-sections/ClientData'),
    Activity: () => import('./form-sections/Activity'),
    Observations: () => import('./form-sections/Observations'),
  },
  props: {
    valid: Boolean,
    crm: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rows: [],
    selectedProducts: [],
    products: [],
    profile: null,
  }),
  methods: {
    // Valida se os campos obrigatórios estão preenchidos corretmanete
    validate() {
      // this.$refs.form.validate();
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    addProduct(product) {
      this.selectedProducts.push(product)
    },
    removeProduct(product) {
      let idx = this.selectedProducts.indexOf(product)
      this.selectedProducts.splice(idx, 1)
    },
    rowsChanged(rows) {
      this.rows = rows
    },
    seeClientDetails() {
      const routeData = this.$router.resolve({
        path: '/people/clients/' + this.client.id,
      })
      window.open(routeData.href, '_blank')
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
.cke_chrome {
  border-radius: 30px;
  border: 1px solid #aaa !important;
  transition: all 0.2s;

  &:hover {
    border: 1px solid #555 !important;
  }
}
.cke_inner {
  background: none !important;
}
.cke_top {
  border-radius: 30px 30px 0 0;
}
.cke_bottom {
  background: none !important;
  padding: 6px 19px !important;
}
</style>
